<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-button variant="success"> Adicionar Ponto </b-button>
        <h6 class="my-3 text-muted">Pontos de Atendimento</h6>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-for="ponto in pontos" :key="ponto.id" cols="12" md="4">
        <router-link :to="`/pontos/${ponto._id}`">
          <b-card
            align="center"
            class="position-static panel"
            :title="ponto.nome"
          >
            <b-card-text> {{ ponto.local }} </b-card-text>
            <b-card-text
              ><small class="text-muted">{{
                ponto.lastUpdate
              }}</small></b-card-text
            >
          </b-card>
        </router-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCardGroup,
  BCard,
  BCardText,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import { mapState } from "vuex";

import axios from "axios";
import { baseApiUrl } from "@/global";

export default {
  components: {
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BButton,
  },
  directives: {
    Ripple,
  },
  computed: mapState(["user"]),
  data() {
    return {
      pontos: [],
    };
  },
  methods: {
    async getPoints() {
      await axios
        .post(`${baseApiUrl}/pontos`, {
          db: this.user.user.produto.db,
        })
        .then((res) => {
          this.pontos = res.data.data;
        });
    },
  },
  mounted() {
    this.getPoints();
  },
};
</script>
<style scoped>
.panel:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: all 0.5s;
}
</style>